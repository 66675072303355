exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-happy-index-js": () => import("./../../../src/pages/happy/index.js" /* webpackChunkName: "component---src-pages-happy-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-merchant-fulltext-js": () => import("./../../../src/pages/merchant/fulltext.js" /* webpackChunkName: "component---src-pages-merchant-fulltext-js" */),
  "component---src-pages-merchant-happy-js": () => import("./../../../src/pages/merchant/happy.js" /* webpackChunkName: "component---src-pages-merchant-happy-js" */),
  "component---src-pages-merchant-sad-js": () => import("./../../../src/pages/merchant/sad.js" /* webpackChunkName: "component---src-pages-merchant-sad-js" */),
  "component---src-pages-merchant-sent-js": () => import("./../../../src/pages/merchant/sent.js" /* webpackChunkName: "component---src-pages-merchant-sent-js" */),
  "component---src-pages-merchant-whatswrong-js": () => import("./../../../src/pages/merchant/whatswrong.js" /* webpackChunkName: "component---src-pages-merchant-whatswrong-js" */),
  "component---src-pages-sad-index-js": () => import("./../../../src/pages/sad/index.js" /* webpackChunkName: "component---src-pages-sad-index-js" */),
  "component---src-pages-sad-success-js": () => import("./../../../src/pages/sad/success.js" /* webpackChunkName: "component---src-pages-sad-success-js" */),
  "component---src-pages-signed-up-js": () => import("./../../../src/pages/signedUp.js" /* webpackChunkName: "component---src-pages-signed-up-js" */)
}

