import './i18n';
import './src/styles/global.css';
import React from 'react';

export { wrapPageElement } from './wrapPageElement';

export const wrapRootElement = ({ element }) => (
  <>{element}</> // eslint-disable-line react/jsx-no-useless-fragment

);
