import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import en from './lang/en.json';
import de from './lang/de.json';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en,
  de,
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },

    supportedLngs: [
      'en',
      'de',
    ],

    detection: {
      order: ['querystring', 'navigator'],
      lookupQuerystring: 'lng',
    },
  });

export default i18n;
