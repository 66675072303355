// from https://github.com/alexluong/gatsby-packages/issues/41
// fixes 404 in dev on change of query params

import React from 'react';
import { navigate } from 'gatsby';
import { QueryParamProvider } from 'use-query-params';

function generatePath(location) {
  return location.pathname + location.search;
}

const history = {
  push: (location) => {
    navigate(generatePath(location), { replace: false, state: location.state });
  },
  replace: (location) => {
    navigate(generatePath(location), { replace: true, state: location.state });
  },
};

// eslint-disable-next-line import/prefer-default-export
export const wrapPageElement = ({ element, props }) => (
  <QueryParamProvider history={history} location={props.location}>
    {element}
  </QueryParamProvider>
);
